import { DateTime } from '@cheqroom/date-time';
import parseJwt from 'jwt-decode';

import Client from '../services/feature-flags';
import { Plan as PlanGroupkey } from './features';

const ACCESS_TOKEN_KEY = 'accessToken';
const SESSION_TOKEN_KEY = 'sessionToken';

const AUTH_TOKENS_KEY = 'cheqroom_auth_tokens';
const AUTH_URL = process.env.LOGIN_URL as string;

type TokensByWorkspaceId = {
	[workspaceId: string]: TokenPair;
};

export type JwtPayload = {
	email: string;
	exp: number;
	impersonating: boolean;
	role: string;
	sub: string;
	workspace: string;
	organisation: string;
};

export type AuthenticateResponse = {
	access_token: string;
	token_type: string;
	refresh_token: string;
};

type TokenPair = {
	[ACCESS_TOKEN_KEY]: string;
	[SESSION_TOKEN_KEY]: string;
};

interface UserPreferences {
	firstDayOfWeek: 'sunday' | 'monday';
}

export type UserAvatar = {
	O: string;
	XS: string;
	S: string;
	M: string;
	L: string;
};

export interface AnonymousUser {
	organisationId?: string;
	workspace: {
		id: string;
		name?: undefined;
		tags?: undefined;
		isInBetaProgram?: undefined;
		signedUpAt?: undefined;
	};
	email?: undefined;
	id?: undefined;
	name?: undefined;
	picture?: undefined;
	roleId?: undefined;
	timezone?: undefined;
	username?: undefined;
	subscription?: undefined;
	preferences?: undefined;
	contactId?: undefined;
}

export interface User {
	email: string;
	id: string;
	name: string;
	picture: UserAvatar;
	roleId: string;
	timezone: string;
	username: string;
	organisationId: string;
	workspace: {
		id: string;
		name: string;
		tags: string[];
		isInBetaProgram: boolean;
		signedUpAt: DateTime | null;
	};
	subscription: {
		planId: string;
		kind: string;
		status: string;
		plan: {
			family: string;
			groupKey: PlanGroupkey;
		};
	};
	preferences: UserPreferences;
	contactId: string;
}

const MIN_REFRESH_TIME_IN_MINUTES = 5;
const AUTH_TOKEN_URL = process.env.AUTH_TOKEN_URL as string;
const AUTHENTICATION_SERVICE_URL = process.env.AUTH_URL as string;

const LAST_ACTIVE_WORKSPACE_KEY = 'cheqroom_last_active_workspace';

const BASE_URL_PATHS = [
	'calendar',
	'items',
	'kits',
	'contacts',
	'users',
	'reservations',
	'check-outs',
	'spotchecks',
	'reports',
	'admin',
	'profile',
	'welcome',
	'not-found',
];

class Authentication {
	private tokenFetch: Promise<AuthenticateResponse> | null = null;

	constructor() {
		document.addEventListener('visibilitychange', () => {
			if (document.visibilityState === 'visible') {
				this.storeLastActiveWorkspace(this.getWorkspaceId());
			}
		});
	}

	private isTokenAboutToExpire = (expiry: number, minRefreshTime: number): boolean => {
		const timeDifferenceMS = new Date(expiry * 1000).getTime() - new Date().getTime();
		const timeDifferenceMinutes = Math.round(timeDifferenceMS / 60000);

		return timeDifferenceMinutes < minRefreshTime;
	};

	private isTokenExpired = (expire: number): boolean => {
		return DateTime.now().isAfter(DateTime.fromDate(new Date(expire * 1000)));
	};

	private doFetchTokensFromPython = (refreshToken: string): Promise<Response> => {
		return fetch(AUTH_TOKEN_URL, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify({
				refresh_token: refreshToken,
				grant_type: 'refresh_token',
			}),
		});
	};

	private _refetchTokenViaPython = async (currentSessionToken: string): Promise<AuthenticateResponse> => {
		try {
			const response = await this.doFetchTokensFromPython(currentSessionToken);

			if (!response.ok) {
				throw new Error('Session token expired');
			}

			const data = (await response.json()) as AuthenticateResponse;

			if (!data) {
				throw new Error('Could not load data');
			}

			return data;
		} catch (e) {
			const tokens = this.getTokensForWorkspace();

			const data = {
				access_token: tokens.accessToken,
				refresh_token: tokens.sessionToken,
			} as AuthenticateResponse;

			const accessTokenPayload = this.decode(data.access_token);

			// If access token isn't actually expired yet we return tokens that are still valid
			if (!this.isTokenExpired(accessTokenPayload.exp)) {
				return data;
			}

			// If access token is expired we redirect to login page
			this.redirectToLogin();

			throw new Error('Could not fetch data');
		}
	};

	private doFetchTokensFromAuthenticationService = (refreshToken: string): Promise<Response> => {
		return fetch(`${AUTHENTICATION_SERVICE_URL}/auth/tokens`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			credentials: 'include',
			body: JSON.stringify({
				refresh_token: refreshToken,
				grant_type: 'refresh_token',
			}),
		});
	};

	private _refetchTokenViaAuthenticationService = async (refreshToken: string): Promise<AuthenticateResponse> => {
		try {
			const response = await this.doFetchTokensFromAuthenticationService(refreshToken);

			if (!response.ok) {
				throw new Error('Session token expired');
			}

			const data = (await response.json()) as AuthenticateResponse;

			if (!data) {
				throw new Error('Could not load data');
			}

			return data;
		} catch (e) {
			const tokens = this.getTokensForWorkspace();

			const data = {
				access_token: tokens.accessToken,
				refresh_token: tokens.sessionToken,
			} as AuthenticateResponse;

			const accessTokenPayload = this.decode(data.access_token);

			// If access token isn't actually expired yet we return tokens that are still valid
			if (!this.isTokenExpired(accessTokenPayload.exp)) {
				return data;
			}

			// If access token is expired we redirect to login page
			this.redirectToLogin();

			throw new Error('Could not fetch data');
		}
	};

	private refetchToken = async (currentSessionToken: string): Promise<AuthenticateResponse> => {
		if ((await Client.onReady()) && Client.isFeatureEnabled('improved_token_pair', 'dummyUserId')) {
			return this._refetchTokenViaAuthenticationService(currentSessionToken);
		}
		return this._refetchTokenViaPython(currentSessionToken);
	};

	private toStorage = (accessToken: string, refreshToken: string) => {
		const tokensByWorkspaceId = this.fromStorage();
		const accessTokenPayload = this.decode(accessToken);

		tokensByWorkspaceId[accessTokenPayload.workspace] = {
			[ACCESS_TOKEN_KEY]: accessToken,
			[SESSION_TOKEN_KEY]: refreshToken,
		};

		const rawTokens = JSON.stringify(tokensByWorkspaceId);
		localStorage.setItem(AUTH_TOKENS_KEY, rawTokens);
	};

	private fromStorage = (): TokensByWorkspaceId => {
		const rawTokens = localStorage.getItem(AUTH_TOKENS_KEY);
		// #todo remove this bit after a week or so after its been in production since by then no one should
		// have the tokens stored in the "old" way
		if (!rawTokens || rawTokens === '') {
			const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
			const refreshToken = localStorage.getItem(SESSION_TOKEN_KEY);
			if (!accessToken || !refreshToken) {
				return {};
			}

			const accessTokenPayload = this.decode(accessToken);

			// first store the current tokens in the new way
			const tokens = {
				[accessTokenPayload.workspace]: {
					[ACCESS_TOKEN_KEY]: accessToken,
					[SESSION_TOKEN_KEY]: refreshToken,
				},
			};

			const rawTokens = JSON.stringify(tokens);
			localStorage.setItem(AUTH_TOKENS_KEY, rawTokens);

			// clear the tokens since we'll store them in the new way
			localStorage.removeItem(ACCESS_TOKEN_KEY);
			localStorage.removeItem(SESSION_TOKEN_KEY);

			return {
				[accessTokenPayload.workspace]: {
					[ACCESS_TOKEN_KEY]: accessToken,
					[SESSION_TOKEN_KEY]: refreshToken,
				},
			};
		}

		const tokens = JSON.parse(rawTokens) as TokensByWorkspaceId;

		return tokens;
	};

	authenticate = async () => {
		const currentLocation = new URL(window.location.toString());

		const accessToken = currentLocation.searchParams.get('access_token');
		const sessionToken = currentLocation.searchParams.get('session_token');
		const redirectUri = currentLocation.searchParams.get('redirect_uri');

		currentLocation.searchParams.delete('access_token');
		currentLocation.searchParams.delete('session_token');
		currentLocation.searchParams.delete('redirect_uri');

		if (redirectUri) {
			const [path, searchParams] = redirectUri.split('?');
			currentLocation.pathname = path;

			if (searchParams) {
				currentLocation.search = searchParams;
			}
		}

		// can't use app.router.navigate because it isn't initialiased yet
		window.history.replaceState({}, '', currentLocation);

		// If you are redirected from the login page, you will get a access token and session token from the url, so
		// We save them to localStorage for further usage
		if (accessToken && sessionToken) {
			const decodedToken = this.decode(accessToken);
			this.storeLastActiveWorkspace(decodedToken.workspace);

			this.toStorage(accessToken, sessionToken);

			return [accessToken, sessionToken] as const;
		} else {
			this.storeLastActiveWorkspace(this.getWorkspaceId());
			// If you refresh the browser and still have a valid login, we will read from localStorage and check if you are about to expire
			// If something goes wrong, we will log out and redirect back to the login page.
			return [await this.getAccessToken(), this.getSessionToken()] as const;
		}
	};

	decode = (token: string): JwtPayload => {
		return parseJwt<JwtPayload>(token);
	};

	getAccessToken = async (): Promise<string> => {
		const tokens = this.getTokensForWorkspace();
		const accessToken = tokens.accessToken;

		const parsedToken = this.decode(accessToken);

		if (!parsedToken) return Promise.reject('Could not decode JWT token');

		if (this.isTokenAboutToExpire(parsedToken.exp, MIN_REFRESH_TIME_IN_MINUTES)) {
			if (this.tokenFetch) return this.tokenFetch.then((res) => res.access_token);

			try {
				this.tokenFetch = this.refetchToken(this.getSessionToken());
				const { refresh_token: sessionToken, access_token: accessToken } = await this.tokenFetch;

				this.toStorage(accessToken, sessionToken);

				return accessToken;
			} finally {
				this.tokenFetch = null;
			}
		}

		return accessToken;
	};

	getSessionToken = () => {
		const tokens = this.getTokensForWorkspace();
		return tokens.sessionToken;
	};

	logout = (): void => {
		localStorage.removeItem(LAST_ACTIVE_WORKSPACE_KEY);
		localStorage.removeItem(AUTH_TOKENS_KEY);
	};

	redirectToLogin = (): void => {
		const workspaceId = this.getWorkspaceId();
		const hasWorkspaceIdInUrl = authentication.getWorkspaceIdFromPath();

		let redirectUrlWithoutWorkspaceId = window.location.pathname;
		if (hasWorkspaceIdInUrl) {
			redirectUrlWithoutWorkspaceId = `/${window.location.pathname.split('/').slice(2).join('/')}`;
		}

		if (window.location.search) {
			redirectUrlWithoutWorkspaceId = `${redirectUrlWithoutWorkspaceId}${window.location.search}`;
		}

		let loginUrl = new URL(`${AUTH_URL}/workspaces?redirect_uri=${redirectUrlWithoutWorkspaceId}`);
		if (workspaceId) {
			loginUrl = new URL(`${AUTH_URL}/${workspaceId}/login?redirect_uri=${redirectUrlWithoutWorkspaceId}`);
		}

		window.location.replace(loginUrl);
	};

	getUser({ anonymous }: { anonymous: true }): AnonymousUser;
	getUser({ anonymous }: { anonymous: false }): User;
	getUser(): User;
	getUser({ anonymous = false } = {}): User | AnonymousUser {
		const workspaceId = this.getWorkspaceId();
		let organisationId;

		try {
			organisationId = this.getTokensForWorkspace()?.accessToken;
		} catch (e) {
			// noop
		}

		if (anonymous) {
			return {
				organisationId,
				workspace: {
					id: workspaceId,
				},
			};
		}

		const knockoutUser = window.global?.central?.user?.() || {};
		const knockoutContact = window.global?.central?.contact?.() || {};
		const subscription = window.global?.central?.subscription?.() || {};
		const weekStart = window.global?.central?.profile?.()?.weekStart;

		return {
			email: knockoutUser.email,
			id: knockoutUser._id,
			name: knockoutUser.name,
			picture: knockoutUser.picture_url,
			roleId: knockoutUser.role,
			timezone: knockoutUser.timezone,
			username: knockoutUser.login,
			organisationId: organisationId ?? '',
			workspace: {
				id: window.global.central.group.id,
				name: window.global.central.group.name,
				tags: window.global.central.group.raw.tags,
				isInBetaProgram: window.global.central.group.raw.isInBetaProgram,
				signedUpAt: window.global.central.group.raw.signedup
					? DateTime.fromDate(
							typeof window.global.central.group.raw.signedup === 'string'
								? new Date(window.global.central.group.raw.signedup)
								: window.global.central.group.raw.signedup.toDate()
						)
					: null,
			},
			subscription: {
				planId: subscription.subscriptionPlan._id,
				kind: subscription.kind,
				status: subscription.status,
				plan: {
					family: subscription.subscriptionPlan.family,
					groupKey: subscription.subscriptionPlan.groupKey as PlanGroupkey,
				},
			},
			preferences: {
				firstDayOfWeek: weekStart === 1 ? 'monday' : 'sunday',
			},
			contactId: knockoutContact._id,
		};
	}

	private getTokensForWorkspace = (): TokenPair => {
		const workspaceId = this.getWorkspaceId();
		const tokensByWorkspaceId = this.fromStorage();

		const tokens = tokensByWorkspaceId[workspaceId];

		if (!tokens) {
			throw new Error('No tokens for this workspace found');
		}

		return tokens;
	};

	getWorkspaceId = (): string => {
		const fromPath = this.getWorkspaceIdFromPath();
		if (fromPath) {
			return fromPath;
		}

		const fromLastActive = this.getWorkspaceIdFromLastActive();
		if (fromLastActive) {
			return fromLastActive;
		}

		return this.getWorkspaceIdFromToken();
	};

	getWorkspaceIdFromPath = (): string | null => {
		const currentPath = window.location.pathname;

		const firstPathParameter = currentPath.split('/')[1];

		if (BASE_URL_PATHS.includes(firstPathParameter)) {
			return null;
		}

		return firstPathParameter;
	};

	private getWorkspaceIdFromLastActive = (): string | null => {
		return localStorage.getItem(LAST_ACTIVE_WORKSPACE_KEY);
	};

	private getWorkspaceIdFromToken = (): string => {
		const tokensByWorkspaceId = this.fromStorage();

		const workspaceIds = Object.keys(tokensByWorkspaceId);
		if (workspaceIds.length === 0) {
			return '';
		}

		return workspaceIds[0];
	};

	private storeLastActiveWorkspace = (workspaceId: string) => {
		localStorage.setItem(LAST_ACTIVE_WORKSPACE_KEY, workspaceId);
	};
}

const authentication = new Authentication();

export default authentication;
